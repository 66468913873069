import $ from "jquery";
import {handleValidation, validateField} from "../form.js";
import {insertHiddenField, removeHiddenGroupFields} from "./hidden-fields.js";
import {parseBoolean} from "../../../common-type.js";

/** Initialize zipcode field handling. */
export function initializeZipcodeFields() {
    $("input[name=zipcode]").each(function () {
        const zipcodeField = $(this);
        const placeholder = zipcodeField.prop("placeholder") || "12345";
        zipcodeField
            .data("val-required", true)
            .mask("00000", {placeholder: placeholder})
            .prop("pattern", "^(?!000[0-9]{2}$)[0-9]{5}$")
            .prop("minlength", "5")
            .prop("maxlength", "5")
            .prop("aria-label", "Your zip code")
            .prop("autocomplete", "postal-code")
            .prop("type", "tel");

        const id = zipcodeField.prop("id");
        const errorElementId = id + "-error";
        let errorElement = $("#" + errorElementId);

        if (errorElement.length === 0) {
            errorElement = $("<div>", {
                "id": errorElementId,
                "data-selector": "zipcode-error",
                class: "invalid-feedback text-end",
                html: "Please enter your 5-digit zip code",
            }).insertAfter(zipcodeField);
        }

        const errorCityElementId = id + "-city-error";
        let errorCityElement = $("#" + errorCityElementId);

        if (errorCityElement.length === 0) {
            errorCityElement = $("<div>", {
                "id": errorCityElementId,
                "data-selector": "zipcode-city-error",
                class: "invalid-feedback text-end collapse",
                html: "Zip code could not be matched to a city",
            }).insertAfter(zipcodeField);
        }

        const verifiedZipcodeField = insertHiddenField(zipcodeField, "verifiedZipcode", "false", "zipcode-hidden");
        const zipcodeVerifiedField = insertHiddenField(zipcodeField, "zipcodeVerified", "", "zipcode-hidden");

        zipcodeField.on("change", function () {
            if (!parseBoolean(verifiedZipcodeField.val()) || zipcodeField.val() !== zipcodeVerifiedField.val()) {
                verifiedZipcodeField.val("false");
                verifyZipCode(zipcodeField, errorElement, errorCityElement);
            }
        });

        zipcodeField.on("keypress", function () {
            zipcodeField.removeClass("is-valid").removeClass("is-invalid");
        });

        if (validateField(zipcodeField)) {
            zipcodeField.trigger("change");
        }
    });
}

/**
 * Validate the zip code format and verify that the zip code maps to a city.
 *
 * @param {any|jQuery|HTMLElement} zipcodeField The zip code input field.
 * @param {any|jQuery|HTMLElement} zipcodeError The invalid zip code error message.
 * @param {any|jQuery|HTMLElement} cityError The verify city found error message.
 *
 * @returns {boolean} True if the zip code is verified, otherwise false.
 */
export function verifyZipCode(zipcodeField, zipcodeError, cityError) {
    zipcodeField.addClass("verifying").removeClass("is-valid").removeClass("is-invalid");
    removeHiddenGroupFields(zipcodeField, "zipcode-hidden");

    cityError.hide();

    const zipcode = zipcodeField.val();
    if (!handleValidation(zipcodeField, zipcodeError, validateField(zipcodeField))) {
        zipcodeField.removeClass("verifying");
        return false;
    }

    /**
     * Verify the zip code with a third-party provider.
     *
     * @typedef {object} ZipCodeVerificationResponse The ZipCode API response object of data found for a specified zip code.
     * @property {string} zipcodeVerified The zip code that passed verification
     * @property {string} city the city
     * @property {string} county the county
     * @property {string} state the state full name
     * @property {string} stateAbbreviation the state abbreviation
     * @property {string} country the country
     * @property {boolean} verifiedZipcode The verified zip code flag
     */
    $.get({
        url: "/api/zip-codes/" + zipcode + "?zipcode=" + zipcode,
        cache: true,
        dataType: "json",
        success:
            /** @param {ZipCodeVerificationResponse} response */
            function (response) {
                const verifiedZipcode = parseBoolean(response.verifiedZipcode);
                if (verifiedZipcode) {
                    addZipcodeHiddenFields(zipcodeField, response);
                }
                handleValidation(zipcodeField, zipcodeError, verifiedZipcode);
            },
        error: function (xhr) {
            if (xhr.status === 404) {
                cityError.show();
                handleValidation(zipcodeField, cityError, false);
            } else {
                handleValidation(zipcodeField, zipcodeError, xhr.status > 499);
            }
        },
        complete: function () {
            zipcodeField.removeClass("verifying");
        },
    });
}

/**
 * Add hidden input fields for zip code details.
 *
 * @param {any|jQuery|HTMLElement} zipcodeField the zip code input field
 * @param {ZipCodeVerificationResponse} response the response object containing the data
 */
function addZipcodeHiddenFields(zipcodeField, response) {
    const fields = {
        zipcodeVerified: zipcodeField.val(),
        city: response.city,
        county: response.county,
        state: response.state,
        stateAbbreviation: response.stateAbbreviation,
        country: response.country,
        verifiedZipcode: response.verifiedZipcode.toString(),
    };

    for (const [name, value] of Object.entries(fields)) {
        insertHiddenField(zipcodeField, name, value, "zipcode-hidden");
    }

    const data = $("form").serializeArray().reduce((obj, item) => {
        obj[item.name] = item.value;
        return obj;
    }, {});
    const csrfToken = document.querySelector("meta[name=\"csrf-token\"]").getAttribute("content");
    $.post({
        url: "/api/session-data/patch",
        contentType: "application/json",
        data: JSON.stringify(data),
        headers: {
            "X-CSRF-TOKEN": csrfToken,
        },
    });
}
